import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'
import { useSpring, animated } from '@react-spring/web'
import { easeOutQuad } from '/machinery/easings'

import { ContainerSm } from '/features/buildingBlocks/Container'
import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingXxl } from  '/features/buildingBlocks/Heading'

import styles from './VisualQuoteFact.css'

export function VisualQuoteFact({ image, title = undefined, text }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [0.5] })
  const [{ scale }, scaleSpring] = useSpring(() => ({ scale: 1.1, config: { tension: 100, friction: 35 } }))
  const [{ clip }, clipSpring] = useSpring(() => ({ clip: 0, config: { tension: 100, friction: 55 } }))
  const [{ opacity }, opacitySpring] = useSpring(() => ({ opacity: 0 }))

  const imageRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      scaleSpring.start({ scale: lerp({ start: 1.1, end: 1, input: easeOutQuad(progression) }) })
      opacitySpring.start({ opacity: lerp({ start: 0.4, end: 0.8, input: easeOutQuad(progression) }) })
      clipSpring.start({ clip: progression })
    }
  })

  const springConfig = {
    to: {
      opacity: wasInViewport ? 1 : 0,
      transform: wasInViewport ? 'translateY(0)' : 'translateY(40px)'
    },
    config: { tension: 100, friction: 35 }
  }

  const headingSpring = useSpring({ ...springConfig })
  const textSpring = useSpring({ ...springConfig, delay: 100 })

  return (
    <figure className={styles.component}>
      <figcaption className={styles.content}>
        <ContainerSm>
          <div className={styles.inner} ref={elementRef}>
            {title && <animated.div style={headingSpring}><HeadingXxl h='2' layoutClassName={styles.heading}>{title}</HeadingXxl></animated.div>}
            {text && <animated.p className={styles.text} style={textSpring}>{text}</animated.p>}
          </div>
        </ContainerSm>
      </figcaption>
      <animated.div className={styles.clip} style={{
        clipPath: clip.to(x => {
          const pos = lerp({ start: 80, end: 100, input: x })
          const neg = lerp({ start: 20, end: 0, input: x })
          return `polygon(${neg}% ${neg}%, ${pos}% ${neg}%, ${pos}% ${pos}%, ${neg}% ${pos}%)`
        })
      }}>
        <animated.div className={styles.imageContainer} ref={imageRef} style={{ opacity, scale }}>
          {image && <ImageCover layoutClassName={cx(styles.image, styles.desktopVersion)} imgProps={{ loading: 'lazy' }} {... { image }} aspectRatio={16 / 9} />}
          {image && <ImageCover layoutClassName={cx(styles.image, styles.mobileVersion)} imgProps={{ loading: 'lazy' }} {... { image }} aspectRatio={9 / 16} />}
        </animated.div>
      </animated.div>
      <div className={styles.gradient} role="presentation" />
    </figure>
  )
}
